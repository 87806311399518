<template>
  <div class="quiz-list">
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
<!--        <register-modal>-->
<!--          <template #default="{on, attrs}">-->
<!--            <v-btn color="success" v-on="on" v-bind="attrs">-->
<!--              <v-icon class="mr-2">mdi-plus</v-icon>-->
<!--              novo-->
<!--            </v-btn>-->
<!--          </template>-->
<!--        </register-modal>-->
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="question.data"
          :search="tableSearch"
          :loading="question.status === 1"
          fixed-header
          item-key=""
          :items-per-page="5"
          :footer-props="{
            itemsPerPageText: 'linhas por página',
            itemsPerPageOptions: [5, 7, 9,],
            // itemsPerPageOptions: [5, 7, 9, -1],
            itemsPerPageAllText: 'Todos',
            pageText: '{0}-{1} de {2}'
          }"
      >
        <template #item.acoes="{item}">
          <v-layout>
<!--            <details-modal :data="item">-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="detalhes">mdi-card-text</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </details-modal>-->
            <form-modal :data="item">
              <template #default="{on, attrs}">
                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">
                  <v-icon title="editar">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </form-modal>
            <delete-modal>
              <template #default="{on, attrs}">
                <v-btn icon class="ml-1" color="error" v-on="on" v-bind="attrs">
                  <v-icon title="excluir">mdi-delete</v-icon>
                </v-btn>
              </template>
            </delete-modal>
          </v-layout>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import DeleteModal from "@/components/modals/delete-modal";
import FormModal from "@/views/database/perguntas/update";
// import RegisterModal from "@/views/database/perguntas/register";
// import DetailsModal from "@/views/database/perguntas/details";
export default {
  components: {
    // RegisterModal,
    FormModal,
    DeleteModal,
    // DetailsModal
  },
  data: () => ({
    title: "Perguntas",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Descrição", value: "descricao" },
      // { text: "Tipo", value: "tipo" },
      // { text: "Ações", value: 'acoes' },
    ],
  }),
  computed: {
    question() {
      return this.$store.state.database.question;
    }
  },
  mounted() {
    this.$store.dispatch('database/question/getAll')
  },
  methods: {
    mergeData(dataA, dataB) {
      return {...dataA, ...dataB}
    }
  }
}
</script>

<style lang="scss">

</style>
