<template>
  <div class="usuarios-form">
    <v-dialog v-model="show" persistent max-width="1024px">
      <v-card>
        <v-card-title class="mb-4">{{ title }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="form.descricao" label="Descrição" />
              </v-col>
              <v-col cols="12">
                <v-select v-model="form.tipo" :items="tipos" label="Tipo"/>
              </v-col>
              <v-col v-if="form.tipo === 'alternativa'" cols="12">
                <v-list-item>
                  <v-layout align-center justify-end>
                    <v-row no-gutters>
                      <v-col cols="2" class="caption grey--text pl-10">Ordem</v-col>
                      <v-col  class="caption grey--text">Descrição</v-col>
                    </v-row>
                    <v-btn icon @click="addOption(form.opcoes)" title="adicionar">
                      <v-icon color="success">mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-list-item>
                <v-list>
                  <v-divider />
                  <template v-for="(item, i) in getOpcoesSortByOrdem">
                    <v-list :key="i">
                      <v-list-item>
                        <v-layout align-center justify-space-between>
                          <v-row>
                            <v-col cols="1" class="d-flex justify-end align-center mb-2">
                              {{ item.ordem }}
                            </v-col>
                            <v-col cols="1">
                              <v-layout justify-space-center align-start column>
                                <v-btn v-if="i > 0" icon x-small :class="i > 0 ? 'mb-4' : ''" @click="swapUp(i)">
                                  <v-icon x-small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn v-if="i < (getOpcoesSortByOrdem.length - 1)" icon x-small @click="swapDown(i)">
                                  <v-icon x-small>mdi-arrow-down</v-icon>
                                </v-btn>
                              </v-layout>
                            </v-col>
                            <v-col>
                              <v-text-field v-model="item.descricao" flat/>
                            </v-col>
                          </v-row>
                          <v-btn icon color="red" @click="removeOption(form.opcoes, item)" title="remover">
                            <v-icon>mdi-minus-circle</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="mt-10">
            <v-layout justify-end>
              <v-btn color="white" class="mr-4" @click="onClickCancel">Cancelar</v-btn>
              <v-btn color="primary" @click="onClickSave">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Salvar
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    onSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    title: 'Atualizar Pergunta',
    show: false,
    form: {
      descricao: '',
      tipo: '',
      opcoes: []
    },
    tipos: [
      {text: 'Alternativa', value: 'alternativa'},
      {text: 'Alternativa Aberta', value: 'alternativa-aberta'},
      {text: 'Texto aberto', value: 'texto-aberto'}
    ]
  }),
  computed: {
    getOpcoesSortByOrdem() {
      return this.form.opcoes.slice().sort((a, b) => a.ordem - b.ordem)
    }
  },
  methods: {
    onClickSave() {
      this.onSave();
      this.show = false;
    },
    onClickCancel() {
      this.onCancel();
      this.show = false;
    },
    addOption(opc) {
      opc.push({ordem: 0, descricao: ''})
    },
    removeOption(opc, item) {
      this.form.opcoes = opc.filter(e => e.ordem !== item.ordem)
    },
    swapUp(index) {
      const options = this.getOpcoesSortByOrdem;
      if (index >= 1) {
        options[index].ordem--
        options[index - 1].ordem++
      }
    },
    swapDown(index) {
      const options = this.getOpcoesSortByOrdem;
      if (index < (options.length - 1)) {
        options[index].ordem++
        options[index + 1].ordem--
      }
    }
  },
  mounted() {
    this.form = this.data
  }
}
</script>

<style lang="scss">

</style>
