var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quiz-list"},[_c('v-card-title',{staticClass:"text-h4"},[_c('v-layout',{attrs:{"justify-space-between":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.question.data,"search":_vm.tableSearch,"loading":_vm.question.status === 1,"fixed-header":"","item-key":"","items-per-page":5,"footer-props":{
            itemsPerPageText: 'linhas por página',
            itemsPerPageOptions: [5, 7, 9,],
            // itemsPerPageOptions: [5, 7, 9, -1],
            itemsPerPageAllText: 'Todos',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"item.acoes",fn:function({item}){return [_c('v-layout',[_c('form-modal',{attrs:{"data":item},scopedSlots:_vm._u([{key:"default",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"title":"editar"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('delete-modal',{scopedSlots:_vm._u([{key:"default",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"title":"excluir"}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }